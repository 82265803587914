import {Input,Checkbox,Radio} from 'antd';
function BasicInfo(props){
  let {info} = props;
  console.log('详情数据',info);
  return <div className="input_list">
  <div className="input_item center">
    <div className="input_item_title must_item">商品标题</div>
    <div className="input_item_value">
      <Input showCount maxLength={30} />
    </div>
  </div>
  <div className="input_item center">
    <div className="input_item_title">商品副标题</div>
    <div className="input_item_value">
      <Input showCount maxLength={50} />
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title">分类属性</div>
    <div className="input_item_value">
      <div className="radio_row">
        <span className="must_item radio_title">品牌</span>
        <Checkbox>喜家德</Checkbox>
        <Checkbox>金丝雀</Checkbox>
      </div>
      <div className="radio_row">
        <span className="must_item radio_title">类型</span>
        <Radio>车队</Radio>
        <Radio>单辆</Radio>
      </div>
      <Input placeholder="请输入正整数" />
    </div>
  </div>
</div>
};
export default BasicInfo;