import Quill from "quill"
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
import { useEffect, useRef } from "react"
import './editor.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [
      {
          header: 1
      },
      {
          header: 2
      }
  ], // custom button values
  [
      {
          list: 'ordered'
      },
      {
          list: 'bullet'
      }
  ],
  [
      {
          script: 'sub'
      },
      {
          script: 'super'
      }
  ], // superscript/subscript
  [
      {
          indent: '-1'
      },
      {
          indent: '+1'
      }
  ], // outdent/indent
  [
      {
          direction: 'rtl'
      }
  ], // text direction

  [
      {
          size: ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '32px', '48px']
      }
  ], // custom dropdown
  [
      {
          header: [1, 2, 3, 4, 5, 6, false]
      }
  ],

  [
      {
          color: []
      },
      {
          background: []
      }
  ], // dropdown with defaults from theme
  [
      {
          font: []
      }
  ],
  [
      {
          align: []
      }
  ],
  ['link', 'image'],
  ['clean'] // remove formatting button
];
function TextEditor(props){
  var editorBox = useRef();
  var editExmple = null;
  useEffect(()=>{
    if(editExmple === null){
      // 没有完成创建容器
      editExmple = new Quill(editorBox.current,{
        placeholder:'请输入内容',
        theme: 'snow', // or 'bubble'
        modules:{
          toolbar:{
            container:toolbarOptions,
            handlers:{
              'image':function(){
                QuillWatch(editExmple.id)
              }
            }
          },
          
        }
      })
    }
  })
  return <div className="text_editor">
    <div className="text_editor_ctrl"></div>
    <div className="text_editor_container" ref={editorBox}></div>
    <div className="input_info">
    当前已输入100个字符, 您还可以输入10000个字符
    </div>
  </div>
};
export default TextEditor