
import SlideItem from './slide';
function subSlide(props){
  let {children} = props;
  if(!Array.isArray(children))return '';
  if(!children.length)return;
  return <ul className="sub_slides">
    {
      children.map(item=><SlideItem key={item.title} {...item}/>)
    }
  </ul>
};
export default subSlide;