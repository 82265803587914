import Renovation from './renovation';
import InfoMange from './infoMange';
export default {
  title:'商家管理',
  pageKey:'shopMange',
  path:'renovation',
  opening:true,
  icon:require('../../static/icon/shop.png'),
  children:[
    {
      title:'店铺装修',
      path:'renovation',
      Component:Renovation
    },
    {
      title:'商家信息管理',
      path:'infoMange',
      Component:InfoMange
    }
  ]
};