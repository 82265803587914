import SelectBlock from './selectBlock';
import {Input,Radio,Select} from 'antd';
const {Option} = Select;
function OrderAttr(props){
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title must_item" style={{marginTop:'11px'}}>是否能下单</div>
    <div className="input_item_value row">
      <SelectBlock title="无需下单" subTitle="线上预约"/>
      <SelectBlock title="正常商品" subTitle="标价下单"/>
      <SelectBlock title="预约金商品" subTitle="预约金下单"/>
    </div>
  </div>
  <div className="input_item center">
    <div className="input_item_title must_item">预约金价格</div>
    <div className="input_item_value">
      <Input showCount maxLength={30} />
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">预约金抵扣价格</div>
    <div className="input_item_value">
      <Input showCount maxLength={30} />
      <p className="text_info">预约抵扣价格默认=预约金价格。就表示纯预约，无抵扣。抵扣价格必须大于等于预约金。</p>
      <p className="text_info2">举例：预约金200元，抵扣价格1000元。<br/>预约金200元，抵扣价格为200元。</p>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">最低需购买数量</div>
    <div className="input_item_value">
      <Input showCount maxLength={30} />件
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">限制购买数量</div>
    <div className="input_item_value">
      <div>
        <Radio>不限制购买数量</Radio>
        <Radio>限制单用户购买数量</Radio>
      </div>
      <div className="pay_limit">
        <span className="pay_limit_info" style={{marginRight:'10px'}}>按周期限购</span>
        <Select style={{width:'80px',marginRight:'20px'}}>
          <Option value="day">每天</Option>
          <Option value="week">每周</Option>
          <Option value="month">每月</Option>
        </Select>
        <Input placeholder="正整数" style={{width:'80px'}} />
        <span style={{marginLeft:'10px'}}>件</span>
      </div>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">积分抵扣现金</div>
    <div className="input_item_value">
      <div>
        <Radio>不可抵扣</Radio>
        <Radio>可以使用红丝积金</Radio>
      </div>
      <div className="pay_limit">
        <span className="pay_limit_info" style={{marginRight:'10px'}}>最大可使用积分数</span>
        <Input style={{width:'80px'}} placeholder="不限制" />
      </div>
    </div>
  </div>
</div>
};

export default OrderAttr;