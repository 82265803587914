/**
 * 主视图
 * 登陆页
 * 后台内容页
 */
import { Routes, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store/index';
import routes from './router/index';
import 'antd/dist/antd.css';
import './App.css';
export default function App(props){
  return <Provider store={store}>
    <Routes>
      {
        routes.map(route=><Route
          path={route.path}
          element={<route.component />  }
          key={route.title}
          exact={route.exact}
        >
          {
            route.children && route.children.map(child=>{
              return <Route 
                element={<child.component />}
                path={child.path}
                key={child.path}
              />
            })
          }
        </Route>)
      }
    </Routes>
  </Provider>
}