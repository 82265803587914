import axios from 'axios';
const BaseUrl = '/api/';//测试
// const BaseUrl = '/';    //生产
const myAxios = axios.create({
  timeout:20000,
  baseURL:BaseUrl
});
// 响应拦截器
myAxios.interceptors.response.use((response)=>{
  let {data,status} = response;
  if(status != 200){
    return Promise.reject('加载失败')
  };
  if(data.code != 200){
    return Promise.reject('数据加载失败')
  }
  return response.data;
});
// 请求拦截器
myAxios.interceptors.request.use((config)=>{
  return config;
});
export default myAxios;