import axios from './axios';
import api from './api';
export default {
  // 登录
  login(params){
    return axios.post(api.login,params)
  },
  // 注册
  register(params){
    return axios.register(api.register,params)
  },
  getGoodsDetail(params){
    return axios.post(api.getGoodsDetail,params)
  }
}