import {Link} from 'react-router-dom';
function mainSlide(props){
  let {title,icon,children,path} = props;
  return <li>
    <div className="firstMenu">
      <Link to={path} className='slide_link'>
        {icon ? <img className="slide_icon" src={icon} alt=""/>:''}
        <span>{title}</span>
        {children ? <img className="open_icon" src={require('../../static/icon/down.png')} /> : ''}
      </Link>
    </div>
    {children}
</li>
};
export default mainSlide;