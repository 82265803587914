import {Radio,Table} from 'antd';
function SaleAttr(props){
  const columns = [
    {
      title:'现价'
    },
    {
      title:'原价'
    },
    {
      title:'库存'
    }
  ];
  return <div className="input_list">
  <div className="input_item center">
    <div className="input_item_title must_item">是否有规格</div>
    <div className="input_item_value">
      <Radio>一口价</Radio>
      <Radio>有多层规格</Radio>
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item" style={{marginTop:'10px'}}>价格库存</div>
    <div className="input_item_value">
      <Table 
        columns={columns} 
        bordered
        dataSource={[]}
      ></Table>
    </div>
  </div>
</div>
};
export default SaleAttr;