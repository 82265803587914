import SlidePages from "../../router/slidePage.js";
import {Link} from 'react-router-dom';
import SubSlide from './subSlide';
import MainSlide from './mainSlide';
import './index.css';
// function subSlide(children = []){
//   if(Array.isArray(children) && children.length > 0){
//     var slides = children.map(item=>{
//       return <li key={item.title}>
//         <Link to={item.path} className="slide_link">{item.title}</Link>
//       </li>
//     });
//     return <ul className="sub_slides">
//       {slides}
//     </ul>
//   }else{
//     return '';
//   }
// }
export default function SlideMenus(){
  return <ul className="slideList">
  {
    SlidePages.filter(item=>item.opening).map(item=>{
      return <MainSlide key={item.title} {...item} >
        <SubSlide children={item.children}></SubSlide>
      </MainSlide>
    })
  }
</ul>
}