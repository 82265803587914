export default {
  title:'商品/案例管理',
  pageKey:'goodsMange',
  path:'goodsMange',
  opening:true,
  icon:require('../../static/icon/goods.png'),
  children:[
    {
      title:'商品管理',
      path:'goodsMange'
    },
    {
      title:'案例管理',
      path:'caseMange'
    }
  ]
};