import {Radio,Input} from 'antd';
function Logistic(){
  return <div className="input_list">
        <div className="input_item">
          <div className="input_item_title must_item">发货方式</div>
          <div className="input_item_value">
            <Radio>不可抵扣</Radio>
            <Radio>可以使用红丝积金</Radio>
          </div>
        </div>
        <div className="input_item">
          <div className="input_item_title must_item">运费</div>
          <div className="input_item_value">
          <Input showCount maxLength={30} placeholder="请输入正整数" />件
          </div>
        </div>
        <div className="input_item">
          <div className="input_item_title must_item">发货方式</div>
          <Radio>无</Radio>
          <Radio>本商品多少件包邮</Radio>
          <Radio>本商品满多少元包邮</Radio>
          <Radio>本商家满多少元包邮</Radio>
        </div>
        <div className="input_item">
          <div className="input_item_title must_item">多少件包邮</div>
          <div className="input_item_value">
          <Input showCount maxLength={30} placeholder="请输入正整数" />件
          </div>
        </div>
        <div className="input_item">
          <div className="input_item_title must_item"> 满多少元包邮</div>
          <div className="input_item_value">
          <Input showCount maxLength={30} placeholder="请输入正整数" />元
          </div>
        </div>
        <div className="input_item">
          <div className="input_item_title must_item">库存计数</div>
          <Radio>买家下单减库存</Radio>
          <Radio>买家付款减库存</Radio>
        </div>
      </div>
};
export default Logistic