import './index.css';
import { useState } from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Radio,Table} from 'antd';
import api from '../../../http/api';
import { useNavigate } from 'react-router';

function GoodsMange(props){
  const [page,setPage] = useState(0);
  let navigate = useNavigate()
  function createGood(){
    navigate('/main/goodDetail')
  };
  let goodsList = [
    {
      id:1079,// id
      goods_header_img:'9174a8b9f64fd842027f209b3cd0414e.jpg',// 商品封面
      goods_caption:'测试商品婚车',// 商品名称
      goods_money:100,// 价格
      num:100,// 库存
      toSales:100,// 销量
      goods_update_time:'2022-02-01',// 更新时间
      presell_selective:1,// 上架状态
    }
  ];
  const columns = [
    {
      title:'排序',
      align:'center',
      width:80,
      render:(col,row,index)=>{
        return <span>{page * 10 + index+1}</span>
      }
    },
    {
      title:'ID',
      align:'center',
      width:80,
      dataIndex:'id'
    },
    {
      title:'商品封面',
      align:'center',
      dataIndex:'goods_header_img',
      width:132,
      render:(text)=>{
        return <img className="shop_img" src={api.imgUrl + text} />
      }
    },
    {
      title:'商品名称',
      align:'center',
      dataIndex:'goods_caption'
    },
    {
      title:'价格',
      align:'center',
      dataIndex:'goods_money',
      render:(text)=>{
        return <div className="price">¥{text}</div>
      }
    },
    {
      title:'库存',
      align:'center',
      dataIndex:'num'
    },
    {
      title:'销量',
      align:'center',
      dataIndex:'toSales'
    },
    {
      title:'更新时间/状态',
      align:'center',
      render:(row)=>{
        return <div className="status">
          <p>{row.goods_update_time}</p>
          <p className={row.presell_selective == 1? 'status_success' :'status_full'}>{row.presell_selective == 1? '上架中' :'仓库中'}</p>
        </div>
      }
    },
    {
      title:'操作',
      align:'center',
      width:190,
      render:()=>{
        return <div className="table_action">
          <div className="table_btn edit_btn">编辑</div>
          <div className="table_btn off_btn">下架</div>
        </div>
      }
    }
  ];
  return <div className="goods_mange">
    <div className="header_info">
      <div className="page_title">商品管理</div>
      <div className="add_goods_btn" onClick={createGood}>
        <PlusOutlined />
        <span className="add_good_btn_text">新增商品</span>
      </div>
    </div>
    <div className="filter_info">
      <div className="status_filter">
        <Radio.Group defaultValue="a" buttonStyle="slide">
          <Radio.Button value="a">全部</Radio.Button>
          <Radio.Button value="b">上架中</Radio.Button>
          <Radio.Button value="c">仓库中</Radio.Button>
        </Radio.Group>
      </div>
      <div className="search_filter">
        <input placeholder="商品名称或商品ID" className="search_input" />
        <div className="search_btn">搜索</div>
      </div>
    </div>
    <div className="table_data_info">
      <Table 
        bordered={true} 
        dataSource={goodsList} 
        columns={columns} 
        rowKey={row=>row.id}
      />
    </div>
  </div>
};
export default GoodsMange;