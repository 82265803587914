import { ImgUpload,TextEditor } from '../../../components';
function PicDesc(){
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title must_item"> 上传主图</div>
    <div className="upload_value">
      <p className="upload_info_text">展示在商家详情页的商品封面，最佳尺寸为670*670像素，比例为1:1。<span className="form_blue_color">（仅支持上传一张）</span></p>
      <ImgUpload />
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title must_item">上传轮播图</div>
    <div className="upload_value">
      <p className="upload_info_text">展示在案例详情页的顶部横幅处，最佳尺寸为1372*914像素，比例为16:10。<span className="form_blue_color">（支持上传多张））</span></p>
      <ImgUpload />
    </div>
  </div>
  <div className="input_item">
    <div className="input_item_title" style={{marginTop:'16px'}}>图文详情</div>
    <TextEditor />
  </div>
  <div className="input_item">
    <div className="input_item_title" style={{marginTop:'16px'}}>购买须知</div>
    <TextEditor />
  </div>
</div>
};
export default PicDesc;