import {DatePicker,Radio} from 'antd';
import moment from 'moment';
const {RangePicker} = DatePicker;
function SaleInfo (){
  return <div className="input_list">
  <div className="input_item">
    <div className="input_item_title">上架配置</div>
    <div>
      <Radio.Group options={[
        {label:'立即上架',value:'立即上架'},
        {label:'定时上架',value:'定时上架'},
        {label:'放入仓库',value:'放入仓库'}
      ]} />
      <div className="timer_select">
        <div className="timer_title">定时上架时间</div>
        <RangePicker 
          renges={{
            Today:[moment(),moment()],
            'This Month':[moment().startOf('month'),moment().endOf('month')]
          }}
          showTime
          format="YYYY-MM-DD HH:mm:ss"
        ></RangePicker>
      </div>
    </div>
  </div>
</div>
};
export default SaleInfo;