import './index.css';
import InfoBlock from './infoBlock';
import BasicInfo from './basicInfo';
import SaleAttr from './saleAttr';
import OrderAttr from './orderAttr';
import PicDesc from './picDesc';
import Logistic from './logistics';
import AfterSale from './afterSale';
import SaleInfo from './saleInfo';
import api from '../../../http/index';
import { useEffect, useState } from 'react';
const PageConfig = [
  {
    title:'基础信息',
    component:BasicInfo
  },
  {
    title:'销售属性',
    component:SaleAttr
  },
  {
    title:'订单属性',
    component:OrderAttr

  },
  {
    title:'图文描述',
    component:PicDesc
  },
  {
    title:'物流信息',
    component:Logistic
  },
  {
    title:'售后服务',
    component:AfterSale
  },
  {
    title:'上架信息',
    component:SaleInfo
  }
]
function GoodsDetail(props){
  const [goodsDetail,setGoodsDetail] = useState({})
  useEffect(()=>{
    console.log('组件更新');
    api.getGoodsDetail({id:'1308'})
    .then(res=>{
      setGoodsDetail(res.data);
    })
    .catch(err=>{
      
    })
  },[]);
  function SetValue(key,value){
    
  };
  return  <div className="good_detail">
    <div className="detail_title">新增商品</div>
    {
      PageConfig.map(item=>{
        return <InfoBlock
          title={item.title}
          key={item.title}
          changeVal={SetValue}
        >
          <item.component info={goodsDetail} />
        </InfoBlock>
      })
    }
    <div className="submit">
      <div className="btn_fixed">
        <div className="submit_btn_item fail">取消</div>
        <div className="submit_btn_item ok">提交</div>
      </div>
    </div>
  </div>
};
export default GoodsDetail;