import './registerMerchant.css';
import { useState, useEffect, useRef } from 'react';
import phone_icon from '../../static/icon/phone.png';
import password_icon from '../../static/icon/password.png';
import password_show_icon from '../../static/icon/password_show.png';
import password_hide_icon from '../../static/icon/password_hide.png';
import auto_login_icon from '../../static/icon/auto_login.png';
import auto_login_active_icon from '../../static/icon/auto_login_active.png';
import http from '../../http/index';
import { message } from 'antd';
import {useNavigate} from 'react-router';
import {useSelector, useDispatch} from 'react-redux'
function RegisterMerchant(props) {
    const [autoLogin, setAutoLogin] = useState(auto_login_icon); // 是否自动登录
    const phoneRef = useRef();
    const passwordRef = useRef();
    const [passwordType, setPasswordType] = useState('password');
    const [passwordShow, setPasswordShow] = useState(password_hide_icon);
    const [loginBtnBg, setLoginBtnBg] = useState('#D8D8D8');
    const dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(()=> {
        if (localStorage.getItem('token')) {
            navigate('/main');
        }
    });
    // 展示密码 or 隐藏密码
    function showPasswordFlag() {
        if (passwordType == 'text') {
            setPasswordType('password');
            setPasswordShow(password_hide_icon);
        } else {
            setPasswordType('text');
            setPasswordShow(password_show_icon);
        }
    }
    // 登录账号只能输入数字
    function changeEvent(e) {
        let value = e.target.value.replace(/[^\d]/g, '');
        if (value != '' && passwordRef.current.value != '') {
            setLoginBtnBg('#FC3455');
        } else {
            setLoginBtnBg('#D8D8D8');
        }
        phoneRef.current.value = value;
    }
    // 密码赋值
    function passwordFn(e) {
        passwordRef.current.value = e.target.value;
        if (phoneRef.current.value != '' && e.target.value != '') {
            setLoginBtnBg('#FC3455');
        } else {
            setLoginBtnBg('#D8D8D8');
        }
    }
    // 自动登录
    function autoLoginFlag() {
        if (autoLogin == auto_login_icon) {
            setAutoLogin(auto_login_active_icon);
        } else {
            setAutoLogin(auto_login_icon);
        }
    }
    // 登录
    function loginAction() {
        let params = {
            user_phone: phoneRef.current.value,
            password: passwordRef.current.value
        };
        if (params.user_phone.length != 11) {
            message.info('手机号格式不正确');
            return
        }
        http.login(params).then((res) =>{
            dispatch({
                type: 'SET_USER_INFO',
                data: res.data.user_info
            });
            if (autoLogin == auto_login_icon) {
                localStorage.setItem('token', res.data.token);
            } 
        });
    }
    return <div className='box'>
        <div className='box_header'>红丝婚礼商家中心</div>
        <div className='box_main'>
            <div className='box_main_model'>
                <div className='model_title'>账号登录</div>
                <div className='model_content'>
                    <img src={phone_icon} alt='' className='model_content_icon' />
                    <input ref={phoneRef} type='text' placeholder='请输入登录账号' className='model_content_inp' maxLength="11" onChange={(e) => changeEvent(e)} />
                </div>
                <div className='model_content model_content1'>
                    <img src={password_icon} alt='' className='model_content_icon model_content_icon1' />
                    <input ref={passwordRef} type={passwordType} placeholder='请输入密码' className='model_content_inp' onChange={(e) => passwordFn(e)} />
                    <img src={passwordShow} alt='' className={['model_content_icon', 'model_content_icon2', passwordType == 'text' ? 'model_content_icon3' : null].join(' ')} onClick={showPasswordFlag} />
                </div>
                <div className='model_content2'>
                    <span onClick={autoLoginFlag}>
                        <img src={autoLogin} className='auto_login_icon'></img>
                        <span className='auto_login_text'>下次自动登录</span>
                    </span>
                    <span className=' forgot_password'>忘记密码</span>
                </div>
                <div className='login_btn' onClick={loginAction} style={{ 'background': loginBtnBg }}>登录</div>
                <div className='register_content'>
                    没有账号？
                    <span>免费注册</span>
                </div>
            </div>
            <div className='box_main_bottom'> 沪公网安备 3101040200692号 | 沪ICP备17007425号</div>
        </div>
    </div>
}
export default RegisterMerchant;

