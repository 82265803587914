import Login from '../views/login/login';
import Register from '../views/register/register';
import RegisterMerchant from '../views/registerMerchant/registerMerchant';
import SubmitQualification from '../views/submitQualification/submitQualification';
import Main from '../views/main/index';
import Renovation from '../views/shopMange/renovation';
import InfoMange from '../views/shopMange/infoMange';
import GoodsMange from '../views/goodsMange/goodsMange';
import CaseMange from '../views/goodsMange/caseMange';
import GoodsDetail from '../views/goodsMange/goodsDetail';
var routers  = [
  {
    path:'/login',
    title:'登陆页',
    component:Login,
    exact:true
  },
  {
    path:'/register',
    title:'注册账号',
    component:Register,
    exact:true
  },
  {
    path:'/registerMerchant',
    title:'注册商户',
    component:RegisterMerchant,
    exact:true
  },
  {
    path:'/submitQualification',
    title:'提交资质',
    component:SubmitQualification,
    exact:true
  },
  {
    title:'首页',
    path:'/main/*',
    component:Main,
    children:[
      {
        path:'renovation',
        component:Renovation
      },
      {
        path:'infoMange',
        component:InfoMange
      },
      {
        path:'goodsMange',
        component:GoodsMange
      },
      {
        path:'caseMange',
        component:CaseMange
      },
      {
        path:'goodDetail',
        component:GoodsDetail
      }
    ]
  }
];
export default routers;