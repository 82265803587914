import { Outlet} from 'react-router-dom';
import {SlideMenus,Header} from '../../components/index';
import './index.css';
function MianPage(){
  return <div className="page_content">
  <Header></Header>
  <div className="main_page">
    <SlideMenus></SlideMenus>
    <div className="main_page_content">
      <Outlet />
    </div>
  </div>
</div>
}
export default MianPage;