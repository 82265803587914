import {Checkbox} from 'antd';
function AfterSale(){
  return <div className="input_list">
    <div className="input_item">
      <div className="input_item_title">售后服务</div>
      <Checkbox.Group 
        options={[
          {label:'提供发票',value:'提供发票'},
          {label:'随时可退',value:'随时可退'},
          {label:'过期可退',value:'过期可退'},
          {label:'支持7天无理由退款',value:'支持7天无理由退款'}
        ]}
      />
    </div>
  </div>;
};
export default AfterSale;